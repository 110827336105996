import React, { useState } from "react";
import Manager from "./Manager";
import "antd/dist/antd.dark.css";
import { Button, Card, Input, Layout } from "antd";
import Meta from "antd/lib/card/Meta";

function App() {
  const [id, setId] = useState<string>("test");
  const [connected, setConnected] = useState(false);

  return (
    <div className="App">
      {!connected ? (
        <Layout
          style={{
            flex: 1,
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgb(51, 51, 51)",
          }}
        >
          <Card style={{ width: 300 }}>
            <Meta title="Enter your cluster id" />
            <Input
              onChange={(e) => setId(e.target.value)}
              value={id}
              onPressEnter={() => setConnected(true)}
              placeholder="Cluster id (case-sensitive)..."
            />
            <Button
              style={{ width: "100%", marginTop: 4 }}
              onClick={() => setConnected(true)}
            >
              Go
            </Button>
          </Card>
        </Layout>
      ) : (
        <Manager onClose={() => setConnected(false)} id={id} />
      )}
    </div>
  );
}

export default App;
