import { DateTime } from "luxon";
import React from "react";
import {
  Area,
  AreaSeries,
  Gradient,
  GradientStop,
  Line,
  LinearYAxis,
  LineChart,
  Stripes,
} from "reaviz";

export interface UsageAxisProps {
  points: number[];
  minimum: number;
  maximum: number;
  lastUpdate: DateTime;
  secondsBetweenPoints: number;
}

const UsageAxis = ({
  points,
  minimum,
  maximum,
  lastUpdate,
  secondsBetweenPoints,
}: UsageAxisProps) => {
  const data = points.map((point, idx) => ({
    id: idx.toString(),
    key: lastUpdate
      .plus({ second: (idx - points.length + 1) * secondsBetweenPoints })
      .toJSDate(),
    data: point,
  }));

  return (
    <LineChart
      height={350}
      yAxis={<LinearYAxis domain={[minimum, maximum]} />}
      series={
        <AreaSeries
          animated={false}
          symbols={null}
          interpolation="smooth"
          markLine={null}
          area={
            <Area
              mask={<Stripes />}
              gradient={
                <Gradient
                  stops={[
                    <GradientStop offset="10%" stopOpacity={0} key="start" />,
                    <GradientStop offset="80%" stopOpacity={1} key="stop" />,
                  ]}
                />
              }
            />
          }
          line={<Line strokeWidth={3} />}
        />
      }
      data={data}
    />
  );
};

export default UsageAxis;
