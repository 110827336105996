import React from "react";
import { Device } from "./Models";
import { DateTime } from "luxon";
import UsageAxis from "./UsageAxis";
import { Divider } from "antd";

export interface DeviceViewProps {
  device: Device;
}

const DeviceView = ({ device }: DeviceViewProps) => (
  <div style={{ padding: 32 }}>
    <Divider>CPU Usage (%)</Divider>
    <UsageAxis
      points={device.cpuusage.map((cpu) => cpu / (device.cores * 10))}
      minimum={0}
      maximum={100}
      lastUpdate={DateTime.fromISO(device.lastUpdate)}
      secondsBetweenPoints={5}
    />
    <Divider>Memory Usage (GB)</Divider>
    <UsageAxis
      points={device.memoryusage.map((x) => x / 1_000_000_000)}
      minimum={0}
      maximum={device.total_memory / 1_000_000_000}
      lastUpdate={DateTime.fromISO(device.lastUpdate)}
      secondsBetweenPoints={5}
    />
  </div>
);

export default DeviceView;
