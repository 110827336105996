import { Card } from "antd";
import Meta from "antd/lib/card/Meta";
import { Device } from "./Models";
import { DiLinux, DiWindows, DiAndroid, DiApple } from "react-icons/di";
import {
  MdOutlineRadioButtonChecked,
  MdOutlineRadioButtonUnchecked,
} from "react-icons/md";
import { memToString, cpuUsage } from "./Utils";
import { createElement, CSSProperties, memo } from "react";
import { DateTime } from "luxon";
import { IconType } from "react-icons";

export interface DeviceItemProps {
  device: Device;
}

const types: { [key: string]: IconType } = {
  Linux: DiLinux,
  Windows: DiWindows,
  Android: DiAndroid,
  MacOS: DiApple,
  Apple: DiApple,
};

export const SystemIcon = ({
  type,
  size,
  style,
}: {
  type: string;
  size: number;
  style?: CSSProperties;
}) =>
  createElement(Object.hasOwn(types, type) ? types[type] : DiLinux, {
    size,
    style,
  });

const Status = ({ connected }: { connected: boolean }) => (
  <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
    {connected ? (
      <>
        <MdOutlineRadioButtonChecked color="green" style={{ marginRight: 4 }} />{" "}
        connected
      </>
    ) : (
      <>
        <MdOutlineRadioButtonUnchecked color="red" style={{ marginRight: 4 }} />{" "}
        disconnected
      </>
    )}
  </div>
);

const StatusMemo = memo(Status);

const DeviceItem = ({ device }: DeviceItemProps) => (
  <Card size="default">
    <Meta
      avatar={<SystemIcon type={device.type} size={48} />}
      title={device.hostname}
      description={<StatusMemo connected={device.connected} />}
      style={{ marginBottom: 8 }}
    />
    <Meta
      description={`Memory ${memToString(device.memory, 2)}/${memToString(
        device.total_memory,
        0,
      )} GB`}
    />
    <Meta description={`CPU ${cpuUsage(device.cores, device.cpu)}`} />
    <Meta
      description={`Updated ${DateTime.fromISO(
        device.lastUpdate,
      ).toRelative()}`}
    />
  </Card>
);

export default DeviceItem;
