export const memToString = (
  memory: number | undefined,
  rounding: number,
): string => {
  memory = (memory ?? 0) / 1_000_000_000;
  return memory.toFixed(rounding);
};

export const cpuUsage = (cores: number, cpu: number): string =>
  (cpu / (cores * 10)).toFixed(0) + "%";
