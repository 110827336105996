import { Layout, List, Menu, MenuProps } from "antd";
import React, { useEffect, useState } from "react";
import DeviceItem, { SystemIcon } from "./DeviceItem";
import DeviceView from "./DeviceView";
import { SelectInfo } from "rc-menu/lib/interface";
import {
  ArrowLeftOutlined,
  DesktopOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import { useCallback } from "react";
import { Device, WsMessage } from "./Models";
import renderEmpty from "antd/lib/config-provider/renderEmpty";

const { Content, Sider } = Layout;

export interface ManagerProps {
  id: string;
  onClose?: () => void;
}

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const Manager = ({ id, onClose }: ManagerProps) => {
  const [selected, setSelected] = useState<Device | undefined>();
  const [collapsed, setCollapsed] = useState(false);
  const [devices, setDevices] = useState<{ [key: string]: Device }>({});

  useEffect(() => {
    console.log("Connecting...");
    const socket = new WebSocket(`wss://spring.raph.workers.dev/${id}/client`);
    socket.onopen = () => {
      console.log("Connected !");
    };

    socket.addEventListener("message", (event) => {
      const data = JSON.parse(event.data as string) as WsMessage;
      console.log(data);

      setDevices((prev) => {
        if (!!data.devices) {
          for (const device of data.devices) {
            prev[device.id] = device;
          }
        }
        if (!!data.updates) {
          for (const update of data.updates) {
            if (!Object.hasOwn(prev, update.device_id)) continue;
            const device = prev[update.device_id];
            device.cpuusage = [
              ...device.cpuusage.slice(device.cpuusage.length >= 100 ? 1 : 0),
              update.cpu,
            ];
            device.memoryusage = [
              ...device.memoryusage.slice(
                device.memoryusage.length >= 100 ? 1 : 0,
              ),
              update.memory,
            ];
          }
        }
        return { ...prev };
      });
    });

    return () => socket.close();
  }, [setDevices]);

  const items: MenuItem[] = [
    getItem("Back to home", "0", <ArrowLeftOutlined />),
    getItem("Overview", "1", <PieChartOutlined />),
    getItem(
      "Devices",
      "2",
      <DesktopOutlined />,
      Object.values(devices).map((device) =>
        getItem(
          device.hostname,
          "device-" + device.id,
          <SystemIcon
            style={{ marginRight: 4 }}
            type={device.type}
            size={16}
          />,
        ),
      ),
    ),
  ];

  const onSelect = useCallback(
    (item: SelectInfo) => {
      if (item.key === "1") setSelected(undefined);
      else if (item.key === "0") onClose?.();
      else if (item.key.startsWith("device-")) {
        const id = item.key.slice("device-".length);
        setSelected(devices[id]);
      }
    },
    [setSelected, devices],
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Menu mode="inline" theme="dark" items={items} onSelect={onSelect} />
      </Sider>
      <Layout className="site-layout">
        <Content style={{ margin: "0 16px" }}>
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            {!selected ? (
              <List
                style={{ padding: 16 }}
                grid={{
                  gutter: 16,
                  xs: 1,
                  sm: 2,
                  md: 3,
                  lg: 3,
                  xl: 4,
                  xxl: 6,
                }}
                rowKey={(d) => d.id}
                dataSource={Object.values(devices)}
                renderItem={(item) => (
                  <List.Item onClick={() => setSelected(item)}>
                    <DeviceItem device={item} />
                  </List.Item>
                )}
                children={
                  Object.values(devices).length === 0 ? (
                    <div className="ant-list-empty-text">
                      {renderEmpty("List")}
                      Launch the device script on a node to see it here
                    </div>
                  ) : undefined
                }
              />
            ) : (
              <DeviceView device={selected} />
            )}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Manager;
